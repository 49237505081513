import { Link } from "gatsby"
import React, { useContext } from "react"
import ItemCard from "./ItemCard"
import SearchInput from "./SearchInput"
import FiltersIcon from "../../images/filters.inline.svg"
import CloseIcon from "../../images/icon-close-circle.inline.svg"
import {
  ArticlesWrapper,
  FiltersButton,
  SearchHeader,
} from "../../styles/knowledge"
import { PhiStateContext } from "../../context"

function LearnPane({
  posts = [],
  onSearch,
  onFiltersToggleClick,
  filtersState,
}) {
  const { mobileMenu } = useContext(PhiStateContext)

  return (
    <section>
      <SearchHeader isMobileMenuOpen={mobileMenu}>
        <SearchInput onSearch={onSearch} />
        <FiltersButton
          onClick={onFiltersToggleClick}
          variant="ghost"
          isActive={false}
          icon={filtersState ? <CloseIcon /> : <FiltersIcon />}
        />
      </SearchHeader>
      <ArticlesWrapper>
        {posts.map((post) => {
          let tags = []

          if (post.levels) {
            tags = [...post.levels]
          }

          if (post.types) {
            tags = [...tags, ...post.types]
          }

          return (
            <Link to={`/learn/${post.slug}`} key={post.slug}>
              <ItemCard key={post.contentful_id} item={post} tags={tags} />
            </Link>
          )
        })}
      </ArticlesWrapper>
    </section>
  )
}

export default LearnPane
