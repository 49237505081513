import React from "react"
import SidebarCard from "./SidebarCard"
import Radio from "./Radio"
import Checkbox from "./Checkbox"
import Tag from "./Tag"
import { H4 } from "../../styles"
import Button from "../Button"

function LearnSidebar({ initialValues, state, setState }) {
  function isLevelSelected(level) {
    return state.level && state.level.contentful_id === level.contentful_id
  }

  function isTypeSelected(type) {
    return Boolean(
      state.types.find((x) => x.contentful_id === type.contentful_id)
    )
  }

  function isTagSelected(tag) {
    return Boolean(
      state.tags.find((x) => x.contentful_id === tag.contentful_id)
    )
  }

  return (
    <>
      <SidebarCard>
        <header>
          <H4 color={({ theme }) => theme.colors.grey}>Category</H4>
          <Button
            size="small"
            variant="ghost"
            onClick={() =>
              setState({
                ...state,
                level: null,
              })
            }
          >
            Clear
          </Button>
        </header>

        {initialValues.levels.map((level) => (
          <Radio
            key={level.contentful_id}
            label={level.title}
            name="level"
            checked={isLevelSelected(level)}
            onChange={(x) => {
              if (x) {
                setState({
                  ...state,
                  level,
                })
              } else {
                setState({
                  ...state,
                  level: null,
                })
              }
            }}
          />
        ))}
      </SidebarCard>
      <SidebarCard>
        <H4 color={({ theme }) => theme.colors.grey} margin="0 0 2.4rem">
          Tags
        </H4>

        <Tag.Group>
          {initialValues.tags.map((tag) => (
            <Tag
              key={tag.contentful_id}
              selected={isTagSelected(tag)}
              onRemove={() =>
                setState({
                  ...state,
                  tags: state.tags.filter(
                    (x) => x.contentful_id !== tag.contentful_id
                  ),
                })
              }
              onSelect={() =>
                setState({
                  ...state,
                  tags: [...state.tags, tag],
                })
              }
            >
              {tag.title}
            </Tag>
          ))}
        </Tag.Group>
      </SidebarCard>
    </>
  )
}

export default LearnSidebar
