import { graphql } from "gatsby"
import React, { useState } from "react"
import LearnPane from "../../components/knowledge/LearnPane"
import LearnSidebar from "../../components/knowledge/LearnSidebar"
import { matchSorter } from "match-sorter"
import Layout from "../../layouts/default"
import { H1, H2, Smaller } from "../../styles"
import Container from "../../components/Container"
// import TabNav from "../../components/knowledge/TabNav"
import styled, { useTheme } from "styled-components"
import { Aside, AsidePaneWrapper, IntroText } from "../../styles/knowledge"
import SEO from "../../components/seo"

const StyledContainer = styled(Container)`
  padding-top: calc(var(--header-height) + 2rem);
`

export default function LearnPage({ data }) {
  const inner_text = (x) => {
    function* text_nodes(x) {
      if (x.nodeType === 'text')
        yield x.value
      else if (Array.isArray(x.content))
        for (const y of x.content)
          yield* inner_text(y);
    }
    return Array.from(text_nodes(x)).join(' ');
  }

  const posts = data.allContentfulPost.nodes
  const levels = data.allContentfulLevel.nodes
  const types = data.allContentfulType.nodes
  const theme = useTheme()
  const tags = data.allContentfulTag.nodes.filter((tag) => tag.visible)
  const [filtersOpen, setFiltersOpen] = useState(false)
  const toggleFilters = () => setFiltersOpen(!filtersOpen)

  const [learnSidebar, setLearnSidebar] = useState({
    types: [],
    tags: [],
    level: null,
  })
  const [filteredPosts, setFilteredPosts] = useState(posts)

  return (
    <Layout>
      <SEO title="Learn Surface 3D Modeling" description="Tips and Tricks for 3D Models and 3D Design on Cloud" />
      <StyledContainer as="main">
        <IntroText>
          <p>Welcome to the knowledge section of Phi, a powerful cloud-based application for modeling & design. In this section, you will find valuable information and resources that will help you make the most of Phi's capabilities and achieve your design goals more efficiently.</p>

          <p>Whether you're a seasoned professional or just starting out in the world of 3D modeling, Phi offers a user-friendly interface and a robust set of tools that can help you bring your vision to life.</p>

          <p>In this knowledge section, our team of experts has curated a collection of resources that will help you learn the ins and outs of Phi and take your skills to the next level.</p>

          <p>So whether you're looking to improve your workflow, enhance your design skills, or simply stay up to date with the latest trends in 3D modeling, the knowledge section of Phi is the perfect place to start. Let's dive in!</p>
        </IntroText>
        <Smaller color={theme.colors.grey}>Make the most out of Phi</Smaller>
        <H1 as={H2} margin="1.2rem 0 3.2rem" color={theme.colors.offWhite}>
          Shared knowledge
        </H1>

        <AsidePaneWrapper>
          <Aside isOpen={filtersOpen}>
            <LearnSidebar
              initialValues={{
                tags,
                levels,
                types,
              }}
              state={learnSidebar}
              setState={(state) => {
                setLearnSidebar(state)
                const fs = []
                if (state.level)
                  fs.push(post =>
                    post.levels
                    && post.levels.some(x => x.contentful_id === state.level.contentful_id)
                  )

                if (state.types.length)
                  fs.push(post =>
                    state.types.some(type =>
                      post.types
                      && post.types.some(x => x.contentful_id === type.contentful_id)
                    ))

                if (state.tags.length)
                  fs.push(post =>
                    state.tags.every(tag =>
                      post.tags
                      && post.tags.some(x => x.contentful_id === tag.contentful_id)
                    ))

                setFilteredPosts(posts.filter(x => fs.every(f => f(x))))
              }}
            />
          </Aside>

          <LearnPane
            posts={filteredPosts}
            onFiltersToggleClick={toggleFilters}
            filtersState={filtersOpen}
            onSearch={(phrase) => {
              if (phrase) {
                setFilteredPosts(
                  matchSorter(
                    posts,
                    phrase,
                    {
                      keys: [
                        x => x.title ?? '',
                        x => x.subtitle ?? '',
                        x => x.tags?.filter(tag => tag.title)?.map(tag => tag.title).join(' ') ?? '',
                        x => x.body ? inner_text(JSON.parse(x.body.raw)) : '',
                        x => x.hidden ?? '',
                      ],
                    }
                  )
                )
              } else {
                setFilteredPosts(posts)
              }

              setLearnSidebar({
                level: null,
                tags: [],
                types: [],
              })
            }}
          />
        </AsidePaneWrapper>
      </StyledContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query learnPage {
    allContentfulPost(
      filter: { slug: { ne: "demo" } }
      sort: { order: ASC, fields: order }
    ) {
      nodes {
        contentful_id
        levels {
          contentful_id
          title
        }
        tags {
          contentful_id
          title
          visible
        }
        slug
        title
        subtitle
        body {
            raw
        }
        types {
          contentful_id
          title
        }
        featuredVideo {
          contentful_id
          url
          name
        }
        featuredImage {
          fluid(maxWidth: 720) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    allContentfulLevel(sort: { fields: order, order: ASC }) {
      nodes {
        contentful_id
        title
      }
    }
    allContentfulType(sort: { fields: order, order: ASC }) {
      nodes {
        contentful_id
        title
      }
    }
    allContentfulTag(sort: { fields: order, order: ASC }) {
      nodes {
        contentful_id
        title
        visible
      }
    }
  }
`
