import styled from "styled-components"
import Button from "../Button"

// const SidebarCard = styled.div`
//   background: var(--color-shark-3);
//   border-radius: 4px;
//   padding: 16px;
//   display: grid;
//   gap: 16px;
//   margin-bottom: 8px;

//   h5 {
//     color: var(--color-shark-4);
//     margin-bottom: 8px;
//     font-size: 18px;
//     font-style: normal;
//     font-weight: 700;
//     line-height: 22px;
//     letter-spacing: 0em;
//   }
// `

const SidebarCard = styled.div`
  background: ${({ theme }) => theme.colors.greyDark};
  border-radius: 0.4rem;
  padding: 1em;
  margin-bottom: 0.8rem;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.4rem;
  }
`

export default SidebarCard
