import React from "react"
import IconClose from "../../images/icon-close-circle.inline.svg"
import styled from "styled-components"
import { gap, _size } from "../../styles/functions"
import Button from '../Button'
import './Tag.scss'

function Tag({ children, onRemove, disabled, onSelect, selected }) {
  return (
    <Button
      variant='ghost'
      extraClass='tag'
      tabIndex={0}
      role="button"
      selected={selected}
      onClick={onSelect}
      disabled={disabled}
    >
      <span>{children}</span>
      {selected && (
        <IconClose
          tabIndex={1}
          role="button"
          onClick={(e) => {
            onRemove()
            e.stopPropagation()
          }}
        />
      )}
    </Button>
  )
}

const TagsGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${gap(".8rem")};
`

Tag.Group = TagsGroup

export default Tag
