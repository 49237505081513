import Img from "gatsby-image"
import { transitions } from "polished"
import React from "react"
import styled from "styled-components"
import { Caption, H1, Small } from "../../styles"
import { gap } from "../../styles/functions"
import Vimeo from "./Vimeo"

const Article = styled.article`
  /* --footer-height: ${({ footerHeight }) => footerHeight}; */
  /* contain: content; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.4rem;
  background-color: ${({ theme }) => theme.colors.offBlack};
  box-shadow: 0 0 0 1px transparent;
  border: 1px solid transparent;
  ${transitions(["box-shadow, border-color"], ".2s ease")};
  height: 100%;
  position: relative;

  /* > footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  } */

  &:hover {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.brand};
    border-color: ${({ theme }) => theme.colors.brand};
  }

  /* > [class*="plyr"],
  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - var(--footer-height));
  } */
`

const TagGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: flex-start;
  top: 0;
  left: 0;
  padding: 1.6rem;
  ${gap(".4rem")};
`

const ArticleTag = styled(Caption)`
  --color: ${({ theme }) => theme.colors.offWhite};
  --border-color: ${({ theme }) => theme.colors.black};
  padding: 0.8rem;
  display: inline;
  vertical-align: middle;
  border-radius: 0.4rem;
  color: var(--color);
  border: 1px solid var(--border-color);
  background-color: var(--border-color);
`

const MediaSection = styled.section`
  flex: 1;
  min-height: 150px;
  border-radius: 0.4rem 0.4rem 0 0;
  overflow: hidden;

  @media (max-width: 768px) {
    min-height: unset;
  }
`

const StyledImg = styled(Img)`
  height: 100%;
  img {
    min-width: 100%;
  }
`

const ArticleFooter = styled.footer`
  padding: 1.6rem;
  background-color: ${({ theme }) => theme.colors.greyDark};
`

function ItemCard({ item, tags = [] }) {
  // const footerRef = useRef(null)
  // const [footerHeight, setFooterHeight] = useState("0px")

  // useEffect(() => {
  //   setFooterHeight(
  //     () => `${footerRef.current.getBoundingClientRect().height}px`
  //   )
  // }, [])


  return (
    <Article>
      <MediaSection>
        {item.featuredVideo ? (
          <Vimeo src={item.featuredVideo.url} controls={[]} />
        ) : item.featuredImage ? (
          <StyledImg fluid={item.featuredImage.fluid} alt={item.featuredImage.title} />
        ) : null}
      </MediaSection>
      <ArticleFooter>
        <H1 as={Small}>{item.title}</H1>
      </ArticleFooter>
    </Article>
  )
}

export default ItemCard
