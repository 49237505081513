import { hideVisually, transitions } from "polished"
import React from "react"
import styled from "styled-components"
import { Small } from "../../styles"
import { RadioCheckLabel, RadioElement } from "../../styles/form-elements"

const Wrapper = styled(RadioCheckLabel)`
  --radio-color: ${({ checked, theme }) =>
    checked ? theme.colors.brand : theme.colors.grey};
  --text-color: ${({ theme }) => theme.colors.offWhite};

  &:hover {
    --radio-color: ${({ checked, theme }) =>
      checked ? theme.colors.brandLight : theme.colors.greyLight};
    --text-color: ${({ theme }) => theme.colors.white};
  }

  + label {
    margin-top: 1rem;
  }

  span {
    ${transitions(["color"], ".2s ease")}
  }

  small {
    color: var(---text-color);
  }
`

function Radio({ label, name, checked, block = true, onChange }) {
  return (
    <Wrapper className="radio" checked={checked} block={block}>
      <input
        type="radio"
        name={name}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        css={hideVisually()}
      />
      <RadioElement checked={checked} />
      <Small>{label}</Small>
    </Wrapper>
  )
}

export default Radio
