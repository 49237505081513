import React, { useEffect, useState } from "react"
import IconSearch from "../../images/icon-search.inline.svg"
import ClearSearch from "../../images/icon-close-circle.inline.svg"
import useDebounce from "../../hooks/useDebounce"
import styled from "styled-components"
import { _size } from "../../styles/functions"
import { transitions } from "polished"

const Wrapper = styled.div`
  border-radius: 0.4rem;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.greyDark};
  color: ${({ theme }) => theme.colors.grey};
  ${transitions(["color", "border-color", "background-color"], ".2s ease")}

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.grey};
  }
  &:focus-within {
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.offWhite};
  }
`

const ButtonWrap = styled.button`
  ${_size("4.8rem")};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  flex: 0 0 4.8rem;

  svg {
    width: 100%
    height: auto;
    fill: currentColor;
    ${transitions(["fill"], ".2s ease")}
  }
`

const SearchButton = styled(ButtonWrap)`
  color: currentColor;
`

const ClearButton = styled(ButtonWrap)`
  color: ${({ value, theme }) =>
    value !== "" ? theme.colors.grey : `transparent`};

  &:hover {
    color: ${({ value, theme }) =>
      value !== "" ? theme.colors.offWhite : `transparent`};
  }
`

const StyledInput = styled.input`
  -webkit-appearance: none;
  background: none;
  border: none;
  caret-color: ${({ theme }) => theme.colors.brand};
  flex: 1 1 auto;
  color: currentColor;
  width: 100%;

  &:focus {
    outline: none;
  }

  &::-webkit-search-cancel-button {
    display: none;
  }
`

function SearchInput({ onSearch }) {
  const [phrase, setPhrase] = useState("")
  const debouncedSearchPhrase = useDebounce(phrase, 500)

  useEffect(() => {
    if (debouncedSearchPhrase) {
      onSearch(debouncedSearchPhrase)
    } else {
      onSearch("")
    }
  }, [debouncedSearchPhrase])

  return (
    <Wrapper>
      <SearchButton>
        <IconSearch />
      </SearchButton>
      <StyledInput
        type="search"
        placeholder="Search here...."
        value={phrase}
        onChange={(e) => {
          const value = e.target.value
          setPhrase(value)
        }}
      />
      <ClearButton value={phrase} onClick={() => setPhrase("")}>
        <ClearSearch />
      </ClearButton>
    </Wrapper>
  )
}

export default SearchInput
