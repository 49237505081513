import styled from "styled-components"
import { _size } from "./functions"
import { transitions } from "polished"
import Button from "../components/Button"

export const AsidePaneWrapper = styled.div`
  display: grid;
  grid-template-columns: 28rem auto;
  gap: 4rem;
  padding-bottom: 3.2rem;

  @media (max-width: 1080px) {
    grid-template-columns: 1fr;
  }
`

export const Aside = styled.aside`
  ${transitions(["transform"], ".2s ease")};

  @media (max-width: 1080px) {
    position: fixed;
    top: 0;
    left: 0;
    width: min(28rem + 3.2rem, 80vw);
    transform: translate(-100%);
    z-index: ${({ theme }) => theme.zIndexes.filters};
    background: ${({ theme }) => theme.colors.black};
    overflow-y: auto;
    height: -webkit-fill-available;
    padding: 1.6rem;
    ${({ isOpen }) => isOpen && `transform: translateX(0)`};
  }
`

export const SearchHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
  top: var(--header-height);
  z-index: ${({ theme, isMobileMenuOpen }) =>
    isMobileMenuOpen ? `0` : theme.zIndexes.header};

  @media (max-width: 1080px) {
    position: sticky;
  }

  > * {
    background: ${({ theme }) => theme.colors.offBlack};
  }
`

export const FiltersButton = styled(Button)`
  flex: 0 0 4.8rem;
  ${_size("4.8rem")};
  margin-left: 1.6rem;
  padding: 0;

  @media (min-width: 1081px) {
    display: none;
  }

  [class*="Active"] {
    display: none;
  }

  svg {
    ${_size("1.6em")};
    fill: ${({ theme }) => theme.colors.grey};
    transition: 0.2s ease;

    &:hover {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`

export const ArticlesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.6rem;

  > a {
    display: block;
  }
`

export const IntroText = styled.div`
  padding-block: 3rem;
  color: ${({ theme }) => theme.colors.offWhite};
  columns: 2;
  column-gap: 3rem;
  margin-inline: auto;
  font-size: 1.7rem;

  @media (max-width: 768px) {
    columns: auto;
  }

  > p {
    break-inside: avoid-column;
    text-align: justify;
    margin-block-start: 0;

    &:not(:last-of-type) {
      margin-block-end: 1em;
    }
  }
`
